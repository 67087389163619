import ChartDataLabels from "chartjs-plugin-datalabels";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { overviewChartBaseOptions } from "../../../commons/transactions.common";
import { formatNumberWithDollar } from "../../../utils/numberFormater";
import { getThemeColor } from "../../../utils/theme";

const chartOptions = merge(cloneDeep(overviewChartBaseOptions), {
  plugins: {
    datalabels: {
      fontFamily: "ProximaNovaRegular",
      color: getThemeColor().textColor,
      textAlign: "left",
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
          fontFamily: "ProximaNovaRegular",
          fontSize: 13,
          fontColor: "#fff",
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
  },
});

function IncomeExpenseHorizontalBarChart({ totalIncome, totalExpense }) {
  const { primaryColor } = getThemeColor();
  return (
    <HorizontalBar
      plugins={[ChartDataLabels]}
      options={merge(cloneDeep(chartOptions), {
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMax:
                  Math.max(totalIncome, Math.abs(totalExpense)) * 1.25,
              },
            },
          ],
        },
      })}
      data={{
        labels: ["Ingoing", "Outgoing"],
        datasets: [
          {
            barThickness: 40,
            backgroundColor: [primaryColor, "#162029"],
            data: [totalIncome, Math.abs(totalExpense)],
            datalabels: {
              labels: {
                value: {
                  anchor: "end",
                  align: "end",
                  offset: 10,
                  clamp: true,
                  font: { weight: "bold", size: 14 },
                  formatter(value) {
                    return [formatNumberWithDollar(value), ""];
                  },
                },
                name: {
                  anchor: "end",
                  align: "end",
                  offset: 10,
                  clamp: true,
                  formatter(value, ctx) {
                    return ["", ctx.chart.data.labels[ctx.dataIndex]];
                  },
                },
              },
            },
          },
        ],
      }}
    />
  );
}

export default IncomeExpenseHorizontalBarChart;
